import client, {handleError} from "../utils/client";
import env from "../env";

export const lockSkyBoxRequest = async (skyBoxId: string, lockType: number) => {
    try {
        const response = await client.put(`${env.apiUrl}/api/skyboxes/${skyBoxId}/lock?lockType=${lockType}`);
        return response;
    }
    catch (error) {
        return error.message;
    }
}

export const getSkyBoxListRequest = async () => {
    try {
        const response = await client.get(`${env.apiUrl}/api/skyboxes`);
        return response.data || [];
    }
    catch (error) {
        return error.message;
    }
}

export const sendSignalRequest = async (id, signal) => {
    try {
        const response = await client.post(`${env.apiUrl}/api/redrats/${id}`, {
            signal,
            dataset: "Sky+"
        });
        return response.data || null;
    }
    catch (error) {
        return error.message;
    }
}




