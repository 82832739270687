import axios, { AxiosError } from 'axios';
import env from '../env';
import {removeToken} from "../services/auth.service";

const client = axios.create({
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Content-Encoding': 'identity',
    }
})

client.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        const confirmationToken = localStorage.getItem('confirmationToken');
        const deviceUuid = localStorage.getItem('deviceUuid');
        if (token) config.headers.Authorization = `Bearer ${token}`;
        if (confirmationToken) config.headers.Authorization = `Bearer ${confirmationToken}`;
        if (deviceUuid) config.headers.DeviceUuid = deviceUuid;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)

export const handleError = async (error: AxiosError) => {

    if(await tryProcessErrorMessage(error)) {
        return error.response;
    }

    try {
        const refreshToken = localStorage.getItem("refreshToken");

        const newClient = axios.create({
            headers: {
                'Authorization': `Bearer ${refreshToken}`
            }
        })

        const refreshResp = await newClient.get(`${env.apiUrl}/api/auth/refresh`).catch()
        localStorage.setItem('token', refreshResp.data.accessToken)
        localStorage.setItem('refreshToken', refreshResp.data.refreshToken)

        error.config.headers.Authorization = `Bearer ${refreshResp.data.accessToken}`
        const response = await client.request(error.config);

        return response.data;
    }
    catch (e){
        await redirectToLogin();
    }

    await redirectToLogin();

    return null;
}

const tryProcessErrorMessage = async (error: AxiosError): Promise<boolean> => {
    const {response: resp} = error;

    // Should Handle Token Refresh
    if(error.config && resp && resp.status === 401) {
        return false;
    }

    if(resp && (resp.status === 400 || resp.status === 500)) {
        return true;
    }

    if(!resp) {
        return false;
    }

    const errorMessage = resp.headers['Expired-Trial'] || resp.data || "You don't have such permissions!"
    await redirectToLogin(errorMessage);

    return true;
}

export const redirectToLogin = async (error: string | null = null) => {
    if (window.location.href.includes('/login')) {
        return;
    }

    await removeToken();
    window.location.href = error
        ? '/login' + error && `?errorMessage=${error}`
        : `${env.apiUrl}/azure/logout?redirectUrl=${window.location.origin}/login`;
}

export default client;
